import Swup from 'swup';
import SwupScrollPlugin from '@swup/scroll-plugin';
import SwupPreloadPlugin from '@swup/preload-plugin';
import 'lazysizes';

const swup = new Swup({
	plugins: [
    new SwupPreloadPlugin(),
		new SwupScrollPlugin({
			doScrollingRightAway: false,
			animateScroll: false
		})]
});

$(document).ready(function () {

	
	init();

	
	function init() {
    
    openLinksInNewTab()
    closeLightbox()

    addBodyClass()
    menuToggle() 

    if ($(".page--home")[0]) {
      siteTitleNav() 
    }

    homeNewImgGrid()
    homeIntroFade()
    homeImgCounter()
    homeTicker()

    infoSliders()
    infoExhibitionSlider()
    infoReinitializeSlider()
    infoArtistbookHeight()

    lightbox()
    workCalcCaptionWidth()
    workStickyHeaderHeight()

    publicationsFadeInImg()

    var restartTimer;

    clearTimeout(restartTimer);
    
    restartTimer = setTimeout(function () {
      
      infoReinitializeSlider()
        
    }, 300);

  }

  swup.on('contentReplaced', init);
  swup.on('pageView', init);
  swup.on('contentReplaced', infoReinitializeSlider);
  swup.on('pageView', infoReinitializeSlider);
  swup.on('contentReplaced', menuClose);
  swup.on('pageView', menuClose);



  var scrollTimer;
  var resizeTimer;
      
  $(window).scroll(function () {

    if ($(".page--information")[0]) {
      infoReinitializeSlider()

      clearTimeout(scrollTimer);
    
      scrollTimer = setTimeout(function () {
      
        infoReinitializeSlider()
          
      }, 100);
    }
    

    if ($(".page--publications")[0]) {
      infoReinitializeSlider()

      clearTimeout(scrollTimer);
    
      scrollTimer = setTimeout(function () {
      
        infoReinitializeSlider()
          
      }, 100);
    }
    
  });

  $(window).resize(function () {
    
    menuClose()
    homeIntroFade()
    infoArtistbookHeight()
    resizeSetHeight()
    workCalcCaptionWidth()
    workStickyHeaderHeight()
    homeImgCounter()
    infoReinitializeSlider()
    
    clearTimeout(resizeTimer);
    
    resizeTimer = setTimeout(function () {
      
      infoReinitializeSlider()
      infoExhibitionSlider() 
      infoArtistbookHeight()
      workStickyHeaderHeight()
        
    }, 300);
        
  })

  // $(window).scroll( function() {
	// 	$('.book__gallery').flickity('resize');
	// });

});
